import React, { useContext, useRef, useEffect, useState } from "react";
import { images } from "../utils/image";
import { Col, Row, Spinner } from "react-bootstrap";
import Button from "../component/Button";
import { AuthUserContext } from "../context";
import { Link } from "react-router-dom";
import Response from "./Response";
import { FaCheck } from "react-icons/fa";
const ChatbotResponse = ({ messages, loading }) => {
  const { setCartCount, setCartItems, cartItems } = useContext(AuthUserContext);
  const messagesEndRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddedToCart, setIsAddedToCart] = useState({});
  useEffect(() => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  }, [messages]);

  const handleImageLoad = () => {
    setIsLoading(false);
  };
  const handleAddToCart = (item) => {
    setCartItems((prevItems) => {
      const itemExists = prevItems.some((cartItem) => cartItem.id === item.id);

      if (itemExists) {
        return prevItems.map((cartItem) => {
          if (cartItem.id === item.id) {
            if (cartItem.count < item.quantity) {
              return { ...cartItem, count: cartItem.count + 1 };
            } else {
              return cartItem;
            }
          }
          return cartItem;
        });
      } else {
        return [...prevItems, { ...item, count: 1 }];
      }
    });
    if (
      cartItems.filter((cartItem) => cartItem.id === item.id).length === 0 ||
      cartItems.find((cartItem) => cartItem.id === item.id).count <
        item.quantity
    ) {
      setCartCount((prevCount) => prevCount + 1);
    }
    setIsAddedToCart((prev) => ({ ...prev, [item.id]: true }));
    setTimeout(() => {
      setIsAddedToCart((prev) => ({ ...prev, [item.id]: false }));
    }, 1000);
  };

  return (
    <div>
      <section className="msger mt-2 mb-3">
        <main className="msger-chat">
          {messages?.map((msg, index) => (
            <div key={index} className={`msg ${msg.side}-msg`}>
              <div className="msg-img">
                {msg.side === "left" ? (
                  <img src={images.memojiOne} alt="memojiOne" />
                ) : (
                  <img src={images.memoji} alt="memoji" />
                )}
              </div>
              <div className="msg-bubble">
                <>
                  {msg.side === "left" && typeof msg.text !== "string" ? (
                    <>
                      {msg?.text?.map((item, index) => {
                        return (
                          <div key={index}>
                            {item.type === "text" ? (
                              <>
                                <Response answer={item.content} />
                              </>
                            ) : (
                              <>
                                <div>
                                  {item.content && <hr />}
                                  <Row className="mt-4">
                                    {item?.content?.map((items, index) => (
                                      <div key={index}>
                                        <Link
                                          to={`/product-detail/${items.id}`}
                                          state={{ product: items }}
                                          style={{ textDecoration: "none" }}
                                        >
                                          <Row className="d-flex justify-content-center">
                                            <Col md={2} xs={4}>
                                              {isLoading && (
                                                <div className="image-container d-flex justify-content-center h-100 align-items-center">
                                                  <Spinner
                                                    animation="border"
                                                    variant="light"
                                                    size="sm"
                                                  />
                                                </div>
                                              )}
                                              <img
                                                src={
                                                  items?.image &&
                                                  items?.image !== "nan"
                                                    ? items?.image
                                                    : images.dummy
                                                }
                                                alt="flower"
                                                className="img-thumbnail"
                                                onLoad={handleImageLoad}
                                                style={{
                                                  display: isLoading
                                                    ? "none"
                                                    : "block",
                                                }}
                                              />
                                            </Col>
                                            <Col
                                              md={10}
                                              xs={8}
                                              className="ps-0 d-flex flex-column justify-content-center"
                                            >
                                              <h3 className="product-title">
                                                {items?.name}
                                              </h3>
                                              <div className="price-show">
                                                <span className="price-box d-flex">
                                                  ${items?.price}
                                                  {/* {items?.price &&
                                        !items.price.startsWith("$")
                                        ? `$${items.price}`
                                        : items.price} */}
                                                </span>
                                                <Button
                                                  buttonText={
                                                    isAddedToCart[items.id] ? (
                                                      <div className="d-flex align-items-center">
                                                        <FaCheck
                                                          style={{
                                                            marginRight: "5px",
                                                          }}
                                                        />
                                                        Added to cart
                                                      </div>
                                                    ) : (
                                                      "Add to cart"
                                                    )
                                                  }
                                                  className={
                                                    isAddedToCart[items.id]
                                                      ? "add-to-cart-btn-success"
                                                      : cartItems.some(
                                                          (cartItem) =>
                                                            cartItem?.id ===
                                                              items?.id &&
                                                            cartItem?.count >=
                                                              items?.quantity
                                                        )
                                                      ? "add-to-cart-btn-disable"
                                                      : "add-to-cart-btn"
                                                  }
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleAddToCart(items);
                                                  }}
                                                  disabled={
                                                    isAddedToCart[items.id] ||
                                                    cartItems.some(
                                                      (cartItem) =>
                                                        cartItem?.id ===
                                                          items?.id &&
                                                        cartItem?.count >=
                                                          items?.quantity
                                                    )
                                                  }
                                                  tooltip={"tooltip"}
                                                  title={
                                                    isAddedToCart[items.id]
                                                      ? null
                                                      : cartItems.some(
                                                          (cartItem) =>
                                                            cartItem?.id ===
                                                              items?.id &&
                                                            cartItem?.count >=
                                                              items?.quantity
                                                        ) &&
                                                        "Maximum quantity reached"
                                                  }
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </Link>
                                        <hr />
                                      </div>
                                    ))}
                                  </Row>
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="msg-text">{msg?.text}</div>
                  )}
                </>
              </div>
            </div>
          ))}
          {loading && (
            <div className="loading-message">
              <div className={`msg left-msg`}>
                <div className="msg-img">
                  <img src={images.memojiOne} alt="memoji" />
                </div>
                <div className="msg-bubble">
                  <div className="msg-text">
                    Allow me a moment to gather the details
                    <div className="dots">
                      <span className="dot"></span>
                      <span className="dot"></span>
                      <span className="dot"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </main>
      </section>
    </div>
  );
};

export default ChatbotResponse;
