import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { images } from "../../utils/image";
import Cart from "../../component/Cart";
import { Link } from "react-router-dom";
import Button from "../../component/Button";
import { AuthUserContext } from "../../context";
import { FaMinus } from "react-icons/fa6";
import { MdOutlineAdd } from "react-icons/md";
import { FaTrashAlt } from "react-icons/fa";
import placeOrderCova from "../../services/Order/placeOrderCova.js";
import { toast } from "react-toastify";
import OrderPaymentCova from "../../services/Order/OrderPaymentCova.js";
import { useNavigate } from "react-router-dom";
import Loader from "../../component/Loader.jsx";
import InactivityHandler from "../../component/InactivityHandler.jsx";
import ProductDeleteModal from "../../component/ProductDeleteModal.jsx";
import OrderSuccessModal from "../../component/OrderSuccessModal.jsx";

const CartItems = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showProductDeleteModal, setShowProductDeleteModal] = useState(false);
  const [showOrderPlaceModal, setShowOrderPlaceModal] = useState(false);
  const [productId, setProductId] = useState("");
  const { cartItems, setCartItems, setCartCount, setMessages } =
    useContext(AuthUserContext);
  const [itemCounts, setItemCounts] = useState(
    cartItems.reduce((acc, item) => {
      acc[item.id] = { ...item, count: item.count || 1 };
      return acc;
    }, {})
  );
  const calculateTotalPrice = () => {
    return Object.values(itemCounts).reduce((sum, item) => {
      return sum + parseFloat(item.price) * item.count;
    }, 0);
  };

  const [totalPrice, setTotalPrice] = useState(calculateTotalPrice());
  const [buttonState, setButtonState] = useState({
    text: "Buy Now",
    disabled: false,
  });

  useEffect(() => {
    setTotalPrice(calculateTotalPrice());
  }, [itemCounts]);
  const handleIncrement = (itemId) => {
    setItemCounts((prevCounts) => {
      const newCounts = { ...prevCounts };
      if (newCounts[itemId]) {
        if (newCounts[itemId].count < newCounts[itemId].quantity) {
          newCounts[itemId].count += 1;
        }
      }
      setCartItems((prevItems) => {
        const updatedItems = prevItems.map((item) => {
          if (item.id === itemId) {
            if (item.count < item.quantity) {
              setCartCount((prevCount) => prevCount + 1);
              return { ...item, count: (item.count || 0) + 1 };
            }
          }
          return item;
        });
        return updatedItems;
      });
      return newCounts;
    });
  };

  const handleDecrement = (itemId) => {
    setItemCounts((prevCounts) => {
      const newCounts = { ...prevCounts };
      if (newCounts[itemId]?.count > 1) {
        newCounts[itemId].count -= 1;
        setCartCount((prevCount) => prevCount - 1);
        setCartItems((prevItems) => {
          const updatedItems = prevItems.map((item) =>
            item.id === itemId ? { ...item, count: item.count - 1 } : item
          );
          return updatedItems;
        });
      } else {
        delete newCounts[itemId];
        setCartCount((prevCount) => prevCount - 1);

        setCartItems((prevItems) => {
          const updatedItems = prevItems.filter((item) => item.id !== itemId);
          return updatedItems;
        });
      }

      return newCounts;
    });
  };

  const placeOrder = async () => {
    try {
      setButtonState({ text: "Placing Order", disabled: true });
      setIsLoading(true);

      const products = Object.values(itemCounts).map((item) => ({
        productId: item.id,
        productName: item.name,
        productQuantity: item.count.toString(),
        amount: (item.count * item.price).toString(),
      }));

      const payload = {
        products,
        OrderReference: "Mursaleen Umar",
      };

      const data = await placeOrderCova(payload);

      if (
        data.orderStatus === "SubmittedForFinalProcessing" ||
        data.orderStatus === "ReadyForPayment" ||
        data.orderStatus === "SubmittedForProcessing"
      ) {
        setIsLoading(false);
        setShowOrderPlaceModal(true);
      } else if (data.orderStatus === "NonTransientProcessingFailure") {
        toast.error(data.orderMessage);
        setButtonState({ text: "Buy Now", disabled: false });
      }
    } catch (error) {
      toast.error("Error placing order:", error);
      setButtonState({ text: "Buy Now", disabled: false });
    } finally {
    }
  };

  const paymentProcessing = async (salesData) => {
    try {
      const data = await OrderPaymentCova({ ...salesData, totalPrice });

      if (
        data.orderStatus === "SubmittedForFinalProcessing" ||
        data.orderStatus === "ReadyForPayment"
      ) {
        toast.success("Payment Processed Successfully!");
        setIsLoading(false);
      }

      setButtonState({ text: "Buy Now", disabled: false });
    } catch (error) {
      toast.error("Error processing payment:", error);
      setButtonState({ text: "Buy Now", disabled: false });
    }
  };

  const handleDelete = () => {
    setItemCounts((prevCounts) => {
      const newCounts = { ...prevCounts };
      delete newCounts[productId];
      setCartCount((prevCount) => prevCount - 1);
      setCartItems((prevItems) => {
        const updatedItems = prevItems.filter((item) => item.id !== productId);
        return updatedItems;
      });
      return newCounts;
    });
    setShowProductDeleteModal(false);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <Container fluid>
        <Row className="top-box  position-relative header-diplay">
          <Col
            xl={7}
            lg={8}
            md={10}
            xs={11}
            className="p-0 d-flex justify-content-center "
          >
            <Row className=" w-100 p-0 ">
              <Col
                xs={2}
                className="d-flex justify-content-start align-items-end ps-0"
              >
                <Link to="/">
                  <img src={images.buttonSquare} alt="logo" />
                </Link>
              </Col>
              <Col md={8} xs={8} className="">
                {/* <img src={images.logo} alt="logo" className="logo-width" /> */}
              </Col>
              <Col
                xs={2}
                className="d-flex justify-content-end align-items-end pe-0"
              >
                {Object.values(itemCounts).length > 0 && <Cart />}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="bottom-box-cart d-flex justify-content-center align-items-start">
          <Col
            xl={7}
            lg={8}
            md={10}
            sm={12}
            className=" card-image-cart-item  mb-3 p-4"
          >
            <Row className="pt-2 disoplay-cart-icon-mobile">
              <Col
                xs={2}
                className="d-flex justify-content-start align-items-end ps-0 "
              >
                <Link to="/">
                  <img src={images.buttonSquare} alt="logo" />
                </Link>
              </Col>
              <Col
                md={8}
                xs={8}
                className=" d-flex align-items-center justify-content-center"
              >
                <h2 className="product-details-h2 text-start pt-2">
                  Cart Items
                </h2>
              </Col>
              <Col
                xs={2}
                className="d-flex justify-content-end align-items-end pe-0"
              >
                {Object.values(itemCounts).length > 0 && <Cart />}
              </Col>
            </Row>
            {Object.values(itemCounts).length ? (
              <>
                <Row className="ps-3 pe-3 pt-md-2 pt-5">
                  <Col xs={12} className=" ps-0">
                    <h2 className="product-details-h2 text-start cart-item-mobile-display">
                      Cart Items
                    </h2>
                  </Col>
                </Row>
                <Row className="ps-3 pe-3">
                  {Object.values(itemCounts).map((item) => (
                    <Col
                      key={item.id}
                      xs={12}
                      className="cart-items-box p-3 pe-0 mt-3"
                    >
                      <Row className="w-100 text-start cart-flex-end ">
                        <Col
                          md={9}
                          xs={12}
                          className="d-flex align-items-start"
                        >
                          <Col md={2} xs={4}>
                            <img
                              src={
                                item?.image && item.image !== "nan"
                                  ? item.image
                                  : images.dummy
                              }
                              alt="product"
                              className="img-thumbnail"
                            />
                          </Col>

                          <div className="ps-3">
                            <div className="d-flex align-items-start">
                              <h4 className="cart-organic-h4">{item.name}</h4>
                            </div>
                            <div className="mobile-flex d-flex flex-wrap gap-3 pt-2">
                              <div className="d-flex align-items-center">
                                <span className="product-per-pack-span">
                                  Quantity :
                                </span>
                              </div>
                              <div className="add-minus-box-cart">
                                {item.count > 1 ? (
                                  <FaMinus
                                    size={20}
                                    onClick={() => handleDecrement(item.id)}
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FaTrashAlt
                                    size={16}
                                    onClick={() => {
                                      setProductId(item.id);
                                      setShowProductDeleteModal(true);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                                <div className="box-add">{item.count}</div>
                                <MdOutlineAdd
                                  size={25}
                                  color="#C7A11F"
                                  onClick={() => handleIncrement(item.id)}
                                  style={{
                                    cursor:
                                      item.count >= item.quantity
                                        ? "not-allowed"
                                        : "pointer",
                                    opacity:
                                      item.count >= item.quantity ? 0.5 : 1,
                                  }}
                                  data-bs-toggle="tooltip"
                                  title={
                                    item.count >= item.quantity
                                      ? `Maximum quantity reached: ${item.quantity}`
                                      : "Add one more item"
                                  }
                                  disabled={item.count >= item.quantity}
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center pt-2">
                              <span className="product-per-pack-span">
                                Stock Quantity :
                              </span>
                              <span className="product-per-pack-span-quantity">
                                &nbsp;{item?.quantity}
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col
                          md={3}
                          xs={9}
                          className="d-flex gap-2 pe-md-0 align-items-center justify-content-end mt-md-0 mt-2"
                        >
                          <span className="product-price-cart">
                            ${(parseFloat(item.price) * item.count).toFixed(2)}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
                <hr />
                <Col className="d-flex align-items-center justify-content-between pt-0 pb-0">
                  <span className="total-amount">Total Amount</span>
                  <span className="total-price pe-3">
                    ${totalPrice.toFixed(2)}
                  </span>
                </Col>
                <hr />
                <Row className="d-flex align-items-center justify-content-end pt-2 pb-0">
                  <Col md={5} xs={12}>
                    <Button
                      className="buy-now-btn-detail"
                      buttonText={buttonState.text}
                      disabled={buttonState.disabled}
                      onClick={() => placeOrder()}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <div className="d-flex justify-content-center flex-column align-items-center empyt-cart-items-center">
                <img
                  src={images.empty}
                  alt="empty"
                  className="pt-4"
                  width={"180px"}
                />
                <h3 className="cart-empty-h3 mt-5">Your Cart Is Empty </h3>
                <p className="cart-empty-p mt-3">
                  Looks like you have not added anything to your cart.
                </p>
              </div>
            )}
          </Col>
        </Row>
        <InactivityHandler />
        <ProductDeleteModal
          showProductDeleteModal={showProductDeleteModal}
          setShowProductDeleteModal={setShowProductDeleteModal}
          handleDelete={handleDelete}
        />
        <OrderSuccessModal
          showOrderPlaceModal={showOrderPlaceModal}
          setShowOrderPlaceModal={setShowOrderPlaceModal}
        />
      </Container>
    </>
  );
};

export default CartItems;
