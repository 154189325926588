import React from "react";
import { Modal } from "react-bootstrap";
import Button from "./Button";

const MicDetactModal = ({ showModal, setShowModal, setIsRecordingStart }) => {
  const handleClose = () => {
    setShowModal("");
    setIsRecordingStart(false);
  };
  return (
    <div>
      <Modal
        show={!!showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ background: "rgb(44 44 44 / 83%)" }}
      >
        <Modal.Body className="pt-4 pb-5 text-center modal-style">
          {showModal === "Permission dismissed" ? (
            <p>
              'To Start using Microphone Cova Compare needs access to your
              microphone. Click Settings icon in the address bar and Always
              Allow Cova Compare to access your microphone'
            </p>
          ) : (
            <p>
              Microphone access was denied. To enable it, please change your
              browser settings.
              <br />
              <strong>Tip:</strong> Click the Settings icon in the address bar
              to adjust permissions.
            </p>
          )}
          <Button
            className="btn btn-cofirm"
            buttonText="Close"
            onClick={handleClose}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MicDetactModal;
