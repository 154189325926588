import axios from "axios";
import routes from "../../configs/api";
import { AUTH_TOKEN_KEY } from "../../constants/localstorageKeys";
const difyProductDescription = (data) => {
  return new Promise((resolve, reject) => {
    const URL = routes.DIFY_PRODUCT_DESCRIPTION;
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    axios
      .post(URL, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default difyProductDescription;
