import axios from "axios";
import routes from "../../configs/api";

const login = (data) => {
  return new Promise((resolve, reject) => {
    const URL = routes.LOGIN;
    
    axios
      .post(URL, data)  
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default login;
