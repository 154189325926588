import React from "react";
import { FaMinus } from "react-icons/fa6";
import { MdOutlineAdd } from "react-icons/md";

const AddPlus = ({ className, setCount, count, maxQuantity }) => {
  const handleDecrement = () => {
    if (count > 1) {
      setCount((prevCount) => prevCount - 1);
    }
  };

  const handleIncrement = () => {
    if (count < maxQuantity) {
      setCount((prevCount) => prevCount + 1);
    }
  };

  return (
    <div className={className}>
      <FaMinus
        size={20}
        onClick={handleDecrement}
        style={{
          cursor: count > 1 ? "pointer" : "not-allowed",
          opacity: count > 1 ? 1 : 0.5,
        }}
      />
      <div className="box-add">{maxQuantity === 0 ? 0 : count}</div>
      <MdOutlineAdd
        size={25}
        color="#C7A11F"
        onClick={handleIncrement}
        style={{
          cursor: count < maxQuantity ? "pointer" : "not-allowed",
          opacity: count < maxQuantity ? 1 : 0.5,
        }}
        data-bs-toggle="tooltip"
        title={
          count < maxQuantity ? "Add one more item" : `Maximum quantity reached`
        }
        disabled={count > maxQuantity}
      />
    </div>
  );
};

export default AddPlus;
