import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Auth from "../../../component/Auth";
import { images } from "../../../utils/image";
const Login = () => {
  return (
    <Container fluid style={{ overflowY: "none" }}>
         <Row className="top-box d-flex justify-content-center align-items-center position-relative">
        <Col
          xl={6}
          lg={8}
          md={10}
          xs={11}
          className="p-0 d-flex justify-content-center "
        >
          <Row className=" w-100 p-0 ">
            <Col
              xs={2}
              className="d-flex justify-content-start align-items-end ps-0"
            ></Col>
            <Col md={8} xs={8} className="">
              <img src={images.logo} alt="logo" className="logo-width" />
            </Col>
            <Col
              xs={2}
              className="d-flex justify-content-end align-items-end pe-0"
            >
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bottom-box d-flex justify-content-center align-items-start">
      <Col
          xl={6}
          lg={8}
          md={10}
          sm={12}
          className={`mb-3 d-flex flex-column justify-content-center align-items-center card-image padding-box-bottom`}
        >
            <Auth />
        </Col>
      </Row>
    </Container>
  )
}

export default Login