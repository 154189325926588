import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { images } from "../utils/image";
import Button from "./Button";

const ProductDeleteModal = ({
  showProductDeleteModal,
  setShowProductDeleteModal,
  handleDelete,
}) => {
  return (
    <div>
      {showProductDeleteModal && (
        <Modal
          show={showProductDeleteModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ background: "rgb(44 44 44 / 83%)" }}
        >
          <Modal.Body className="pt-4 pb-5 text-center modal-style">
            <img src={images.alarm} alt="countDown" width={"80px"} />
            <h3 className="still-Active-h3 pt-3">Are you sure?</h3>
            <p className="still-Active-P pt-2">
              You want to remove this product from your cart?
            </p>
            <Row className="d-flex justify-content-center pt-3">
              <Col
                md={10}
                xs={12}
                className="d-flex justify-content-center gap-3 flex-wrap"
              >
                <Button
                  className="btn btn-danger"
                  onClick={() => setShowProductDeleteModal(false)}
                  buttonText="No, cancel!"
                />
                <Button
                  className="btn btn-cofirm"
                  onClick={handleDelete}
                  buttonText="Yes, remove it!"
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default ProductDeleteModal;
