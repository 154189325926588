import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { images } from "../../utils/image";
import Button from "../../component/Button";
import Input from "../../component/Input";
import ChatbotResponse from "../../component/ChatbotResponse";
import Cart from "../../component/Cart";
import { AuthUserContext } from "../../context";
import difyUserChat from "../../services/Chat/difyUserChat";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Auth from "../../component/Auth";
import getUser from "../../services/auth/getUser";
import { useNavigate } from "react-router-dom";
import JSON5 from "json5";
import difyProductDescription from "../../services/Chat/difyProductDescription";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import InactivityHandler from "../../component/InactivityHandler";
import SpeechToText from "../../component/SpeechToText";
const Chatbot = () => {
  const {
    cartCount,
    messages,
    setMessages,
    setConversationId,
    conversationId,
  } = useContext(AuthUserContext);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [checkUserLoading, setCheckUserLoading] = useState(false);
  const [sendButtonDisable, setSendButtonDisable] = useState(false);
  const [userData, setUserData] = useState([]);
  const [isRecordingStarted, setIsRecordingStarted] = useState(false);
  const [isclearButtonHide, setIsClearButtonHide] = useState(false);
  const [inputShow, setInputShow] = useState(false);
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const [isRecordingStart, setIsRecordingStart] = useState(false);

  const recorderControls = useVoiceVisualizer();
  const { isRecordingInProgress } = recorderControls;
  const handleInputChange = (value) => {
    setInputValue(value);
  };
  const handleClear = (event) => {
    event.preventDefault();
    setMessages([]);
    setConversationId("");
  };
  const handleSearch = async (event, hardCodeMessage = null) => {
    event.preventDefault();
    try {
      if (!inputValue.trim() && !hardCodeMessage) return;
      setLoading(true);

      const newMessage = {
        id: messages.length + 1,
        sender: "User",
        text: hardCodeMessage ? hardCodeMessage : inputValue,
        time: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        side: "right",
        conversationId,
      };

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInputValue("");

      const data = await difyUserChat(newMessage);
      const answerText = data.data.answer;
      const responseArray = [];
      const processText = (text) => {
        const arrayRegex = /\[([\s\S]*?)\]/g;
        const objectRegex = /\{([\s\S]*?)\}/g;
        let lastIndex = 0;
        let match;
        while ((match = arrayRegex.exec(text)) !== null) {
          const beforeArrayText = text.slice(lastIndex, match.index).trim();
          const arrayText = match[0].trim();
          lastIndex = arrayRegex.lastIndex;
          if (beforeArrayText) {
            const cleanedText = beforeArrayText
              .replace(/[{}\[\],]/g, "")
              .trim();
            if (cleanedText)
              responseArray.push({ type: "text", content: cleanedText });
          }
          try {
            const correctedData = JSON5.parse(arrayText);
            responseArray.push({ type: "array", content: correctedData });
          } catch (error) {
            console.error("Error parsing JSON array:", error);
          }
        }
        while ((match = objectRegex.exec(text)) !== null) {
          const beforeObjectText = text.slice(lastIndex, match.index).trim();
          const objectText = match[0].trim();
          lastIndex = objectRegex.lastIndex;
          const isInsideArray =
            text.slice(0, match.index).includes("[") &&
            text.slice(lastIndex).includes("]");
          if (!isInsideArray) {
            if (beforeObjectText) {
              const cleanedText = beforeObjectText
                .replace(/[{}\[\],]/g, "")
                .trim();
              if (cleanedText)
                responseArray.push({ type: "text", content: cleanedText });
            }

            try {
              const parsedObject = JSON5.parse(objectText);
              responseArray.push({ type: "aray", content: [parsedObject] });
            } catch (error) {
              console.error("Error parsing JSON object:", error);
            }
          }
        }
        const afterText = text.slice(lastIndex).trim();
        if (afterText) {
          const cleanedText = afterText.replace(/[{}\[\],]/g, "").trim();
          if (cleanedText)
            responseArray.push({ type: "text", content: cleanedText });
        }
      };
      // const processText = (text) => {
      //   const arrayRegex = /\[([\s\S]*?)\]/g;
      //   const objectRegex = /\{([\s\S]*?)\}/g;
      //   let lastIndex = 0;
      //   let match;

      //   while ((match = arrayRegex.exec(text)) !== null) {
      //     const beforeArrayText = text.slice(lastIndex, match.index).trim();
      //     const arrayText = match[0].trim();
      //     lastIndex = arrayRegex.lastIndex;
      //     if (beforeArrayText) {
      //       const cleanedText = beforeArrayText.replace(/[{}\[\],]/g, '').trim();
      //       responseArray.push({ type: "text", content: cleanedText });
      //     }
      //     try {
      //       const correctedData = JSON5.parse(arrayText);
      //       responseArray.push({ type: "array", content: correctedData });
      //     } catch (error) {
      //       console.error("Error parsing JSON array:", error);
      //     }
      //   }

      //   while ((match = objectRegex.exec(text)) !== null) {
      //     const beforeObjectText = text.slice(lastIndex, match.index).trim();
      //     const objectText = match[0].trim();
      //     lastIndex = objectRegex.lastIndex;
      //     if (beforeObjectText) {
      //       const cleanedText = beforeObjectText.replace(/[{}\[\],]/g, '').trim();
      //       responseArray.push({ type: "text", content: cleanedText });
      //     }
      //     try {
      //       const parsedObject = JSON5.parse(objectText);
      //       responseArray.push({ type: "array", content: [parsedObject] });
      //     } catch (error) {
      //       console.error("Error parsing JSON object:", error);
      //     }
      //   }
      //   const afterText = text.slice(lastIndex).trim();
      //   const cleanedText = afterText.replace(/[{}\[\],]/g, '').trim();
      //   if (cleanedText) {
      //     responseArray.push({ type: "text", content: cleanedText });
      //   }
      // };
      processText(answerText);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: messages.length + 1,
          sender: "Bot",
          text: responseArray,
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          side: "left",
        },
      ]);
      setConversationId(data.data.conversation_id);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Oops! Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // const handleSearch = async (event, hardCodeMessage = null) => {
  //   event.preventDefault();
  //   try {
  //     if (!inputValue.trim() && !hardCodeMessage) return;
  //     setLoading(true);

  //     const newMessage = {
  //       id: messages.length + 1,
  //       sender: "User",
  //       text: hardCodeMessage ? hardCodeMessage : inputValue,
  //       time: new Date().toLocaleTimeString([], {
  //         hour: "2-digit",
  //         minute: "2-digit",
  //       }),
  //       side: "right",
  //       conversationId,
  //     };

  //     setMessages((prevMessages) => [...prevMessages, newMessage]);
  //     setInputValue("");

  //     const data = await difyUserChat(newMessage);
  //     const answerText = data.data.answer;
  //     console.log("answerText", answerText);

  //     const responseArray = [];
  //     const processText = (text) => {
  //       const arrayRegex = /\[([\s\S]*?)\]/g;
  //       let lastIndex = 0;
  //       let match;

  //       while ((match = arrayRegex.exec(text)) !== null) {
  //         const beforeArrayText = text.slice(lastIndex, match.index).trim();
  //         const arrayText = match[0].trim();
  //         lastIndex = arrayRegex.lastIndex;

  //         if (beforeArrayText) {
  //           responseArray.push({ type: "text", content: beforeArrayText });
  //         }

  //         try {
  //           const correctedData = JSON5.parse(arrayText);
  //           responseArray.push({ type: "array", content: correctedData });
  //         } catch (error) {
  //           console.error("Error parsing JSON array:", error);
  //         }
  //       }

  //       const afterText = text.slice(lastIndex).trim();
  //       if (afterText) {
  //         responseArray.push({ type: "text", content: afterText });
  //       }
  //     };

  //     processText(answerText);
  //     setMessages((prevMessages) => [
  //       ...prevMessages,
  //       {
  //         id: messages.length + 1,
  //         sender: "Bot",
  //         text: responseArray,
  //         time: new Date().toLocaleTimeString([], {
  //           hour: "2-digit",
  //           minute: "2-digit",
  //         }),
  //         side: "left",
  //       },
  //     ]);
  //     setConversationId(data.data.conversation_id);
  //   } catch (error) {
  //     console.error("Error:", error);
  //     toast.error("Oops! Something went wrong. Please try again later.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const getUsers = async () => {
    try {
      const response = await getUser();
      if (response.user.length > 0) {
        setIsAuth(true);
        setUserData(response.user);
      }
    } catch (e) {
      if (e.status === 401) {
        toast.error("You need to sign in or sign up before continuing");
        navigate("/login");
      } else {
        toast.error(e.message);
      }
    } finally {
      setCheckUserLoading(false);
    }
  };
  useEffect(() => {
    // getUsers();
  }, []);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return (
    <Container fluid style={{ overflowY: "none" }}>
      <Row className=" d-flex justify-content-center align-items-center position-relative">
        <Col
          xl={7}
          lg={8}
          md={10}
          xs={11}
          className="p-0 d-flex justify-content-center "
        >
          <Row className=" w-100 p-0 ">
            <Col
              xs={2}
              className="d-flex justify-content-start align-items-end ps-0"
            ></Col>
            <Col md={8} xs={8} className="">
              {/* <img src={images.logo} alt="logo" className="logo-width" /> */}
            </Col>
            <Col
              xs={2}
              className={`d-flex justify-content-end align-items-end pe-0 ${
                cartCount > 0 && "mt-5"
              }`}
            >
              {cartCount > 0 && <Cart />}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bottom-box d-flex justify-content-center align-items-start mt-5">
        <Col
          xl={7}
          lg={8}
          md={10}
          sm={12}
          className={`mb-3 d-flex flex-column justify-content-center align-items-center card-image padding-box-bottom`}
        >
          {checkUserLoading ? (
            <div
              className=" d-flex flex-column justify-content-center align-items-center"
              style={{ height: "500px" }}
            >
              <Spinner animation="border" variant="light" />
            </div>
          ) : (
            <>
              {messages.length ? (
                <Col
                  xl={11}
                  lg={10}
                  md={10}
                  sm={12}
                  className="pt-4 responive-width-chat-bot"
                >
                  <ChatbotResponse messages={messages} loading={loading} />
                </Col>
              ) : (
                <>
                  <Col md={12} className="">
                    {/* <img
                      src={
                      userData.length && userData[0].avatar
                        ? userData[0]?.avatar
                        : images.robot
                    }                      
                    alt="logo"
                      className="robot-css pt-3 pb-3"
                    /> */}
                  </Col>
                  <Col xl={11} lg={10} md={10} sm={12} className="">
                    <h3 className="cannaEra-h3">Welcome to AI Budtender</h3>
                    <p className="cannbis-p pt-2">
                      Use the power of AI to find answers.
                    </p>
                    <p className="looking-p">What Are You Looking For?</p>
                    <Row className="d-flex justify-content-center align-items-center mb-3 p-md-0 p-2 ">
                      {[
                        { text: "Relaxed", image: images.relaxedSVG },
                        { text: "Exhilarated", image: images.exhilaratedSVG },
                        { text: "Cheerful", image: images.cheerfulSVG },
                        { text: "Energized", image: images.energisedSVG },
                        { text: "Relieved", image: images.relievedSVG },
                        { text: "Creative", image: images.creativeSVG },
                      ].map((mood, index) => (
                        <Col
                          key={index}
                          md={4}
                          sm={6}
                          xs={6}
                          className="p-1 mt-1 "
                        >
                          <Button
                            buttonText={
                              <div className="d-flex align-center">
                                <img
                                  src={mood.image}
                                  alt="logo"
                                  className="icon-width"
                                />
                                <div className="ms-md-3">
                                  <span className="main-text">More</span>
                                  <br />
                                  <span className="sub-text">{mood.text}</span>
                                </div>
                              </div>
                            }
                            onClick={(event) => {
                              handleSearch(
                                event,
                                `Recommend me some products for ${mood.text}`
                              );
                            }}
                            className="more-relaxed-btn"
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </>
              )}

              <Col
                xl={11}
                lg={10}
                md={10}
                sm={12}
                className="search-box dispaly-input"
              >
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    handleSearch(event);
                  }}
                >
                  <Row className="d-flex align-items-center mt-md-2 mb-4 p-2">
                    {messages.length > 0 && !inputShow && (
                      <>
                        <Col
                          md={1}
                          xs={2}
                          className="d-flex justify-content-md-center justify-content-center ps-md-0"
                        >
                          <button
                            type="button"
                            className="send-button"
                            onClick={handleClear}
                            disabled={loading}
                          >
                            <img src={images.clear} alt="clear" />
                          </button>
                        </Col>
                      </>
                    )}

                    <Col
                      md={
                        isRecordingStarted &&
                        (messages.length || !messages.length)
                          ? 12
                          : messages.length > 0
                          ? 10
                          : 11
                      }
                      xs={10}
                      isRecordingStarted
                      className={`${messages.length <= 0 && "ps-md-0"} pe-1`}
                    >
                      <div
                        div
                        className={`w-100 pe-2 pe-md-0 d-flex ${
                          isAudioLoading
                            ? "justify-content-center"
                            : "justify-content-between"
                        }`}
                      >
                        {!inputShow && (
                          <div style={{ width: "97%" }}>
                            <Input
                              value={inputValue}
                              onChange={handleInputChange}
                              setInputValue={setInputValue}
                              status="chatbot"
                              loading={loading}
                              setLoading={setSendButtonDisable}
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  handleSearch(event);
                                }
                              }}
                              readOnly={loading}
                              setIsRecordingStarted={setIsRecordingStarted}
                              setIsClearButtonHide={setIsClearButtonHide}
                              isRecordingStarted={isRecordingStarted}
                              setInputShow={setInputShow}
                              inputShow={inputShow}
                              isAudioLoading={isAudioLoading}
                            />
                          </div>
                        )}
                        <div
                          className={`d-flex align-items-center ${
                            inputShow && "w-100"
                          }`}
                        >
                          <SpeechToText
                            isAudioLoading={isAudioLoading}
                            setIsAudioLoading={setIsAudioLoading}
                            setQuery={setInputValue}
                            query={inputValue}
                            setInputShow={setInputShow}
                            setLoading={setSendButtonDisable}
                            disabled={false}
                            loading={loading}
                            setIsRecordingStart={setIsRecordingStart}
                            setIsRecordingStarted={setIsRecordingStarted}
                            isRecordingStarted={isRecordingStarted}
                            setIsClearButtonHide={setIsClearButtonHide}
                          />
                        </div>
                      </div>
                    </Col>
                    {!isRecordingStarted && (
                      <Col
                        md={1}
                        xs={2}
                        className="d-flex justify-content-md-center justify-content-center ps-md-0 pe-md-0"
                      >
                        <button
                          type="submit"
                          className="send-button"
                          disabled={loading || sendButtonDisable}
                        >
                          {loading ? (
                            <div className="" style={{ width: "50px" }}>
                              <Spinner
                                animation="border"
                                variant="light"
                                size="sm"
                              />
                            </div>
                          ) : (
                            <img src={images.plain} alt="send" />
                          )}
                        </button>
                      </Col>
                    )}
                  </Row>
                </form>
              </Col>
            </>
          )}
        </Col>
      </Row>
      <Row className="diplay-mobile ">
        <Col xl={10} lg={10} md={10} sm={12} className="search-box">
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleSearch(event);
            }}
          >
            <Row className="d-flex align-items-center mt-2 mb-4 p-2 ">
              {messages.length > 0 && !inputShow && (
                <>
                  <Col
                    md={1}
                    xs={2}
                    className="d-flex justify-content-md-center justify-content-center ps-md-0"
                  >
                    <button
                      type="button"
                      className="send-button"
                      onClick={handleClear}
                      disabled={loading}
                    >
                      <img src={images.clear} alt="clear" />
                    </button>
                  </Col>
                </>
              )}

              <Col
                md={messages.length > 0 ? 10 : 11}
                xs={
                  isRecordingStarted && (messages.length || !messages.length)
                    ? 12
                    : messages.length > 0
                    ? 8
                    : 11
                }
                // xs={
                //   isRecordingStarted && messages.length
                //     ? 12
                //     : isRecordingStarted && !messages.length
                //     ? 12
                //     : messages.length && !isRecordingStarted > 0
                //     ? 8
                //     : 11
                // }
                className={messages.length <= 0 ? "p-0" : "p-0"}
              >
                <div
                  div
                  className={`w-100 pe-2 pe-md-0 d-flex ${
                    isAudioLoading
                      ? "justify-content-center"
                      : "justify-content-between"
                  }`}
                >
                  {!inputShow && (
                    <div style={{ width: "97%" }}>
                      <Input
                        value={inputValue}
                        onChange={handleInputChange}
                        setInputValue={setInputValue}
                        status="chatbot"
                        loading={loading}
                        setLoading={setSendButtonDisable}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            handleSearch(event);
                          }
                        }}
                        readOnly={loading}
                        setIsRecordingStarted={setIsRecordingStarted}
                        setIsClearButtonHide={setIsClearButtonHide}
                        setInputShow={setInputShow}
                        inputShow={inputShow}
                        isAudioLoading={isAudioLoading}
                      />
                    </div>
                  )}
                  <div
                    className={`d-flex align-items-center ${
                      inputShow && "w-100"
                    }`}
                  >
                    <SpeechToText
                      isAudioLoading={isAudioLoading}
                      setIsAudioLoading={setIsAudioLoading}
                      setQuery={setInputValue}
                      query={inputValue}
                      setInputShow={setInputShow}
                      setLoading={setSendButtonDisable}
                      disabled={false}
                      loading={loading}
                      setIsRecordingStart={setIsRecordingStart}
                      setIsRecordingStarted={setIsRecordingStarted}
                      isRecordingStarted={isRecordingStarted}
                      setIsClearButtonHide={setIsClearButtonHide}
                    />
                  </div>
                </div>
              </Col>
              {!isRecordingStarted && (
                <Col
                  md={1}
                  xs={1}
                  className="d-flex justify-content-md-center justify-content-center pe-0 ps-0"
                >
                  <button
                    type="submit"
                    className="send-button"
                    disabled={loading || sendButtonDisable}
                  >
                    {loading ? (
                      <div className="" style={{ width: "50px" }}>
                        <Spinner animation="border" size="sm" />
                      </div>
                    ) : (
                      <img src={images.plain} alt="send" />
                    )}
                  </button>
                </Col>
              )}
            </Row>
          </form>
        </Col>
      </Row>
      {messages.length > 0 && <InactivityHandler />}
    </Container>
  );
};

export default Chatbot;
