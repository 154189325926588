import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { images } from "../../utils/image";
import Button from "../../component/Button";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <Container fluid style={{ overflowY: "none" }}>
      <Row className="top-box d-flex justify-content-center align-items-center position-relative">
        <Col
          xl={6}
          lg={8}
          md={10}
          xs={11}
          className="p-0 d-flex justify-content-center "
        >
          <Row className=" w-100 p-0 ">
            <Col md={12} xs={12} className="">
              <img src={images.logo} alt="logo" className="logo-width" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bottom-box d-flex justify-content-center align-items-center">
        <Col
          xl={6}
          lg={8}
          md={10}
          sm={12}
          className=" card-image-cart-item pt-5 pb-5 mb-3 d-flex flex-column justify-content-center align-items-center padding-box-bottom"
        >
          <img src={images.notfound} alt="notfound" className="img-fluid" />
          <Col md={8} xs={12} className="pt-5">
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button buttonText="Go to Home Page" className="goto-home-btn" />
            </Link>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
