import React from "react";

const Button = ({
  className,
  onClick,
  disabled,
  buttonText,
  loading,
  tooltip,
  title,
}) => {
  return (
    <div className="d-grid gap-2">
      <button
        className={className}
        onClick={onClick}
        disabled={disabled || loading}
        data-bs-toggle={tooltip}
        title={title}
      >
        {loading ? "Loading..." : buttonText}
      </button>
    </div>
  );
};

export default Button;
