import React, { useState, useEffect, useContext } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { images } from "../utils/image";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../context";

const OrderSuccessModal = ({ showOrderPlaceModal, setShowOrderPlaceModal }) => {
  const [timeLeft, setTimeLeft] = useState(
    process.env.REACT_APP_ORDER_SUCCESSFUL_TIMER
  );
  const {
    setCartCount,
    setCartItems,
    setMessages,
    setShowModal,
    setConversationId,
  } = useContext(AuthUserContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (showOrderPlaceModal) {
      const timerInterval = setInterval(() => {
        if (timeLeft <= 0) {
          clearInterval(timerInterval);
          setShowOrderPlaceModal(false);
          setCartCount(0);
          setCartItems([]);
          setMessages([]);
          navigate("/");
          setConversationId("");
          setShowModal(false);
        } else {
          setTimeLeft(timeLeft - 1);
        }
      }, 1000);
      return () => clearInterval(timerInterval);
    }
  }, [showOrderPlaceModal, timeLeft]);

  return (
    <div>
      {showOrderPlaceModal && (
        <Modal
          show={showOrderPlaceModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ background: "rgb(44 44 44 / 83%)" }}
        >
          <Modal.Body className="pt-4 pb-5 text-center modal-style">
            <img
              src={images.verified}
              alt="countDown"
              width={"80px"}
              className="pt-2"
            />
            <h3 className="still-Active-h3 pt-3">Order Placed</h3>
            <p className="still-Active-P pt-2">
              Your order has been placed. Please proceed to the main counter for
              payment and receive your order.
            </p>
            <p>
              Redirected to the homepage in: <stong>{timeLeft}</stong> seconds.
            </p>
            <Row className="d-flex justify-content-center pt-3">
              <Col
                md={10}
                xs={12}
                className="d-flex justify-content-center gap-3 flex-wrap"
              >
                <Button
                  className="btn btn-cofirm"
                  buttonText="Back to Chat"
                  onClick={() => {
                    setCartCount(0);
                    setCartItems([]);
                    setMessages([]);
                    setShowOrderPlaceModal(false);
                    navigate("/");
                    setShowModal(false);
                    setConversationId("");
                  }}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default OrderSuccessModal;
