import React, { useState } from "react";
import Input from "./Input";
import { Container, Row, Col } from "react-bootstrap";
import Button from "./Button";
import { MdOutlineFileUpload } from "react-icons/md";
import login from "../services/auth/auth";
import { toast } from "react-toastify";
import { AUTH_TOKEN_KEY, USER_DETAILS } from "../constants/localstorageKeys";
import { useNavigate } from "react-router-dom";
const Auth = () => {
  const [formData, setFormData] = useState({
    chatbotName: "",
    companyName: "",
    password: "",
    avatar: null,
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();
  const [imageName, setImageName] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (field) => (value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const validImageTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/svg+xml",
      ];
      if (!validImageTypes.includes(file.type)) {
        setErrors((prev) => ({
          ...prev,
          avatar: "Only images (jpg, png, gif, svg) are allowed",
        }));
        return;
      }

      setImageName(file.name);
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setFormData((prevData) => ({
        ...prevData,
        avatar: file,
      }));
      setErrors((prev) => ({ ...prev, avatar: null }));
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (!formData.chatbotName)
      newErrors.chatbotName = "Chatbot name is required";
    if (!formData.companyName)
      newErrors.companyName = "Company name is required";
    if (!formData.password) newErrors.password = "Password is required";
    if (!formData.avatar) newErrors.avatar = "Avatar is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!validateFields()) return;
      setIsLoading(true);
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("chatbotName", formData.chatbotName);
      formDataToSubmit.append("companyName", formData.companyName);
      formDataToSubmit.append("password", formData.password);
      formDataToSubmit.append("image", formData.avatar);
      const repsonse = await login(formDataToSubmit);
      if (repsonse) {
        const userDetails = {
          chatbotName: repsonse?.user?.chatbotName,
          companyName: repsonse?.user?.companyName,
          avatar: repsonse?.user?.avatar,
          id: repsonse?.user?.id,
        };
        localStorage.setItem(USER_DETAILS, JSON.stringify(userDetails));
        localStorage.setItem(AUTH_TOKEN_KEY, repsonse?.user?.token);
        toast.success(repsonse.message);
        navigate("/");
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className=" ">
      <Row className="d-flex justify-content-center">
        <Col md={11} xs={12} className="text-start mt-4 pe-0">
          <label className="chatbot-name-label mb-2">Chatbot Name</label>
          <Input
            status="auth"
            placeHolder="Enter Chatbot Name"
            value={formData.chatbotName}
            onChange={handleInputChange("chatbotName")}
          />
          {errors.chatbotName && (
            <span className="text-danger mt-2">{errors.chatbotName}</span>
          )}
        </Col>
        <Col md={11} xs={12} className="text-start mt-4 pe-0">
          <label className="chatbot-name-label mb-2">Company Name</label>
          <Input
            status="auth"
            placeHolder="Enter Company Name"
            value={formData.companyName}
            onChange={handleInputChange("companyName")}
          />
          {errors.companyName && (
            <span className="text-danger">{errors.companyName}</span>
          )}
        </Col>
        <Col md={11} xs={12} className="text-start mt-4">
          <label className="chatbot-name-label mb-2">Password</label>
          <Input
            status="password"
            placeHolder="Enter Password"
            value={formData.password}
            onChange={handleInputChange("password")}
          />
          {errors.password && (
            <span className="text-danger">{errors.password}</span>
          )}
        </Col>
        <Col md={11} xs={12} className="text-start mt-4">
          <label className="chatbot-name-label mb-2">Choose Avatar</label>
          <div
            className={`file-input-wrapper ${
              selectedImage ? "image-selected" : ""
            }`}
          >
            {selectedImage ? (
              <div>
                <img
                  src={selectedImage}
                  alt="Selected"
                  className="image-preview"
                />
                <span className="ps-3 image-name-text">{imageName}</span>
              </div>
            ) : (
              <div>Upload Avatar</div>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="file-input"
            />
            <MdOutlineFileUpload color="#fff" size={25} />
          </div>
          {errors.avatar && (
            <span className="text-danger">{errors.avatar}</span>
          )}
        </Col>
        <Col md={11} xs={12} className="text-start mt-4">
          <Row className="d-flex align-items-center justify-content-end pb-3">
            <Col md={5} xs={12}>
              <Button
                className="buy-now-btn-detail"
                buttonText="Login"
                onClick={handleSubmit}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Auth;
