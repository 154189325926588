import React, { useContext } from "react";
import { images } from "../utils/image";
import { AuthUserContext } from "../context";
import { Link } from "react-router-dom";

const Cart = () => {
  const { cartCount } = useContext(AuthUserContext);
  return (
    <div>
      <Link to="/cart-item">
        <div className="cart-box position-relative">
          <img src={images.cart} alt="cart" className="cart-image" />
          {cartCount > 0 && (
            <div className="cart-count position-absolute top-0 start-100 translate-middle">
              {cartCount}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};

export default Cart;
