import React, { createContext, useState } from "react";
export const AuthUserContext = createContext();

const AuthState = (props) => {
  const [cartCount, setCartCount] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [messages, setMessages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [conversationId, setConversationId] = useState("");
  return (
    <AuthUserContext.Provider
      value={{
        cartCount,
        setCartCount,
        cartItems,
        setCartItems,
        messages,
        setMessages,
        showModal,
        setShowModal,
        setConversationId,
        conversationId
      }}
    >
      {props.children}
    </AuthUserContext.Provider>
  );
};
export default AuthState;
