import React, { useEffect, useState, useRef, useContext } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import { AuthUserContext } from "../context";
import { images } from "../utils/image";

const InactivityHandler = () => {
  const navigate = useNavigate();
  const {
    setCartCount,
    setCartItems,
    setMessages,
    showModal,
    setShowModal,
    setConversationId,
  } = useContext(AuthUserContext);
  const [countdown, setCountdown] = useState(
    process.env.REACT_APP_INACTIVE_SCREEN_TIMER
  );
  const inactivityTimerRef = useRef(null);
  const countdownTimerRef = useRef(null);
  const clearTimers = () => {
    if (inactivityTimerRef.current) clearTimeout(inactivityTimerRef.current);
    if (countdownTimerRef.current) clearInterval(countdownTimerRef.current);
  };
  const resetInactivityTimer = () => {
    if (showModal) return;
    clearTimers();
    setShowModal(false);
    setCountdown(process.env.REACT_APP_INACTIVE_SCREEN_TIMER);
    inactivityTimerRef.current = setTimeout(() => {
      setShowModal(true);
    }, process.env.REACT_APP_MODEL_OPENER__TIMER);
  };
  const stayActive = () => {
    setShowModal(false);
    resetInactivityTimer();
  };

  useEffect(() => {
    if (showModal) {
      countdownTimerRef.current = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(countdownTimerRef.current);
            navigate("/");
            setCartCount(0);
            setCartItems([]);
            setMessages([]);
            setShowModal(false);
            setConversationId("");
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } else {  
      clearTimers();
    }

    return () => {
      clearTimers();
    };
  }, [showModal, navigate, setCartCount, setCartItems, setMessages]);
  useEffect(() => {
    const events = ["mousemove", "click", "keypress", "scroll"];

    const handleActivity = () => {
      if (!showModal) {
        resetInactivityTimer();
      }
    };

    events.forEach((event) => window.addEventListener(event, handleActivity));
    resetInactivityTimer();

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );
      clearTimers();
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <Modal
          show={showModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ background: "rgb(44 44 44 / 83%)" }}
        >
          <Modal.Body className="pt-4 pb-5 text-center modal-style">
            <img src={images.countDown} alt="countDown" width={"150px"} />
            <h3 className="still-Active-h3 pt-3">Are you still there?</h3>
            <p className="still-Active-P pt-2">
              You will be redirected to the homepage. All chat messages and cart
              will be cleared in <strong>{countdown}</strong> seconds
              <br /> if no action is taken.
            </p>
            <Row className="d-flex justify-content-center pt-3">
              <Col
                md={10}
                xs={12}
                className="d-flex justify-content-center gap-3 flex-wrap"
              >
                <Col md={5} xs={12}>
                  <Button
                    className="buy-now-btn-cancel"
                    onClick={() => {
                      clearTimers();
                      setShowModal(false);
                      navigate("/");
                    }}
                    buttonText="Cancel"
                  />
                </Col>
                <Col md={5} xs={12}>
                  <Button
                    className="buy-now-btn-detail"
                    onClick={stayActive}
                    buttonText="Stay Active"
                  />
                </Col>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default InactivityHandler;
