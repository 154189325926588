import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Chatbot from "../page/chatbot";
import ProductDetail from "../page/productDetail";
import CartItems from "../page/cartItems";
import NotFound from "../page/notFound";
import Login from "../page/Auth/login";
import PrivateComponent from "../constants/privateComponent";
import { USER_DETAILS } from "../constants/localstorageKeys";
const MainRoute = () => {
  const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (userDetails && pathname == "/login") {
      navigate("/");
    }
  }, []);
  return (
    <div>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route element={<PrivateComponent />}> */}
          <Route path="/" element={<Chatbot />} />
          <Route path="/product-detail/:id" element={<ProductDetail />} />
          <Route path="/cart-item" element={<CartItems />} />
        {/* </Route> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default MainRoute;
