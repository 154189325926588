import React, { useState } from "react";
import { images } from "../utils/image";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import SpeechToText from "./SpeechToText";
const Input = ({
  value,
  onChange,
  readOnly,
  status,
  placeHolder,
  setInputValue,
  loading,
  setLoading,
  setIsRecordingStarted,
  isRecordingStarted,
  setIsClearButtonHide,
  setInputShow,
  inputShow,
  isAudioLoading
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div>
      {status === "auth" ? (
        <input
          type="text"
          className="nput-box-auth"
          placeholder={placeHolder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          readOnly={readOnly}
        />
      ) : status === "image" ? (
        <input
          type="file"
          className="nput-box-auth"
          placeholder={placeHolder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          readOnly={readOnly}
          accept="image/*"
        />
      ) : (
        // <div
        //   div
        //   className={`w-100 pe-2 pe-md-0 d-flex ${
        //     isAudioLoading
        //       ? "justify-content-center"
        //       : "justify-content-between"
        //   }`}
        // >
        <>
          {!isAudioLoading && !isRecordingStarted && (
              <div className={`input-box  ${readOnly && "read-only"}`}>
                <input
                  type={
                    status === "password" && !showPassword ? "password" : "text"
                  }
                  className="offdK"
                  placeholder={
                    status == "password"
                      ? "Enter Password"
                      : "Ask me anything..."
                  }
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  readOnly={readOnly}
                />
                {status == "password" ? (
                  <>
                    {showPassword ? (
                      <FaEye
                        onClick={handlePasswordToggle}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <FaEyeSlash
                        onClick={handlePasswordToggle}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </>
                ) : (
                  <>{/* <img src={images.microphone} alt="microphone" /> */}</>
                )}
              </div>
          )}
        </>
          

      )}
    </div>
  );
};

export default Input;
